<template>
   <div>
     <transition name="fade">
      
      <article v-if="lezione && (lezione.published == 1 || isAdmin)">
            <template v-if="$route.name == 'Lezione'">
            <header>
              <router-link :to="{ name: 'Classe', params: { classeId: $route.params.classeId}}" class="indietro">
                <svg class="icon" width="16" height="16"><use xlink:href="#back"/></svg>
                Indietro</router-link>
              <h2 v-if="lezione.titolo">{{ lezione.titolo }} <span class="badge badge--ligthgray" v-if="lezione.published == 0">BOZZA</span></h2>
            </header>

            <div v-if="lezione.testo" v-html="lezione.testo" class="testo"></div>

            <div v-if="lezione.risorse">
                <ul v-for="risorsa in lezione.risorse" :key="risorsa.id" class="risorse">
                    <template v-if="risorsa.published == 1 || isAdmin">
                      <li v-if="risorsa.tipo === 'allegato'">
                        <a :href="risorsa.link" target="_blank" class="risorsa_allegato">
                           <svg class="icon" width="22" height="22"><use xlink:href="#attachment" /></svg>
                            {{ risorsa.nome }}
                        </a>
                      </li>


                      <li v-if="risorsa.tipo === 'video'">
                        <router-link :to="{ name: 'Risorsa' , params: {classeId: classeid, lezioneId: lezione.id, risorsaId: risorsa.id }}" class="risorsa_video">
                                <img :src="postrImg(risorsa.video_id)">
                                <svg class="icon" width="22" height="22"><use xlink:href="#video" /></svg>
                                {{ risorsa.nome }}
                        </router-link>
                      </li>
                    </template>
                </ul>
            </div>

            </template>
            
            <router-view/>

      </article>
     </transition>

    </div>

</template>

<script>
import Loading from '@/components/Loading.vue'
import { mapGetters, mapState, mapActions } from 'vuex'

export default {
  name: 'Lezione',

  data: () => {
    return {
      lezione: null,
      title: "",
      detail: ''
    }
  },

  metaInfo() {
        return {
            title: this.title,
        };
   },
  
  components: {
      Loading
  },


  watch: {
      '$route.params': {
          handler(newValue) {
              const { lezioneId } = newValue
              this.loadLezione(lezioneId)
          },
          immediate: true,
      }
  },

  computed: {

    ...mapGetters([
      'isAdmin',
    ]),

    classeid: function() {
      return this.$route.params.classeId
    }


  },

  methods: {

      youTubeGetID(url) {
      url = url.split(/(vi\/|v=|\/v\/|youtu\.be\/|\/embed\/)/);
      return (url[2] !== undefined) ? url[2].split(/[^0-9a-z_\-]/i)[0] : url[0];
      },

      postrImg(url) {
        const id = this.youTubeGetID(url);
        return 'https://img.youtube.com/vi/'+id+'/0.jpg'
      },

      loadLezione(lezioneId) {
                this.lezione = null
                const userData = JSON.parse(localStorage.getItem('user'))
                const token = userData['token']
                const config = {
                  headers: { Authorization: `Bearer ${token}`, 'Access-Control-Allow-Origin': '*', 'Content-Type': 'application/json' }
                };

                axios.get('lezioni/' + lezioneId, config, { timeout: 2})
                .then((response) => {
                    this.lezione = response.data
                    this.title = response.data.titolo;
                    localStorage.setItem("lezioneid", JSON.stringify(response.data.id))
                })
                .catch(e => {
                    console.log(e)
                })
      },
      /*
      videoDetail(id) {
           axios.get('https://www.googleapis.com/youtube/v3/videos?id='+id+'&part=contentDetails')
                .then((response) => {
                    this.detail = response.data
                })
                .catch(e => {
                    console.log(e)
           })
      }
      */
  },


  beforeMount() {
   const lezioneId = this.$route.params.lezioneId
   this.loadLezione(lezioneId)
  },

  beforeDestroy() {
    this.lezione = null
  },



}
</script>
